import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import { Dialog, DialogContent, Fab, IconButton } from "@mui/material";
import { useState } from "react";
import Maps, { MapProps } from './Maps';


export default function MapFab({ center, zoom, markers, onPlaceSelect }: MapProps) {

    const [open, setOpen] = useState<boolean>(false)

    return <>
        <Fab size="large" sx={{
            width: '80px',
            borderRadius: '12px',
            height: '40px',
            textTransform: 'none',
            position: 'fixed',
            top: '90%',     // Position from the top
            left: '50%',    // Position from the left
            transform: 'translate(-50%, -50%)',  // Move it to the exact center
            zIndex: 1000 // ensures it stays above other elements
        }} onClick={() => setOpen(true)}>
            <MapIcon />
            Map
        </Fab>
        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
            <IconButton sx={{
                position: 'absolute',
                top: 30,   // Distance from the top (adjust as needed)
                right: 30, // Distance from the right (adjust as needed)
                zIndex: 1101,  // Ensures it's above other elements in the dialog
            }} onClick={() => setOpen(false)}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Maps center={center} zoom={zoom} markers={markers} onPlaceSelect={onPlaceSelect}></Maps>
            </DialogContent>
        </Dialog>
    </>
}