import { Autocomplete, TextField, capitalize } from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosClient from "../../core/axiosclient";

type CityProps = {
  label: string;
  minWidth?: string | number;
  onInput: (city: string) => void;
};

export default function CitySelectMinimal({
  label,
  minWidth,
  onInput,
}: CityProps) {
  const [searchCity, setSearchCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState<string[]>(["paris", "barcelona", "tokyo", "rome", "prague", "istanbul", "los angeles"]);

  useEffect(() => {
    const controller = new AbortController();
    // If searchCity is 2 letters or more
    if (searchCity.length > 1) {
      axiosClient
        .get(`/cities?q=${searchCity}`, {
          signal: controller.signal,
        })
        .then((res) => {
          const result = res.data;
          setCities(result);
          setLoading(false);
        })
        .catch(() => { });
      setLoading(true);
    }

    return () => {
      try {
        controller.abort();
      } catch (error) {
        //slient
      }
    };
  }, [searchCity]);

  const handleOnInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    if (reason === "input") {
      event.preventDefault();
      setSearchCity(value);
    }
  };

  return (
    <>
      <Autocomplete 
        disablePortal
        id="combo-box-demo"
        options={cities}
        loading={loading}
        getOptionLabel={(c) => capitalize(c)}
        getOptionKey={(c) => c}
        isOptionEqualToValue={(option, value) => option === value}
        onInputChange={handleOnInputChange}
        onChange={(e, v, r, d) => {
          if (v) {
            onInput(v);
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder="Search" />
        )}
      />
    </>
  );
}
