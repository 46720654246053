import axios from "axios";

// Set config defaults when creating the instance
const axiosClient = axios.create({
  //baseURL: "http://localhost:8787",
  baseURL: "https://api.journeyjuggler.com",
  withCredentials: true
});

// Alter defaults after instance has been created
axiosClient.defaults.headers.post["Content-Type"] = "application/json";

export default axiosClient;
