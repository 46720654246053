import { Box, Button, Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays } from "date-fns";
import { useState } from "react";
import CitySelectMinimal from "./City/CitySelectMinimal";

type StarterProps = {
    onSubmit: (city: string, startDate: Date, endDate: Date) => void;
};

export default function TripPlannerStarter({ onSubmit }: StarterProps) {
    const [city, setCity] = useState<string>();
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 2));
    return <>
        <Box sx={{
            width: '50%',      // Control the width of the Box
            margin: '0 auto',   // Center the Box horizontally
        }}>
            <Stack width={"100%"} spacing={3} margin={3}>
                <Typography gutterBottom variant="h6">Start planing</Typography>
                <CitySelectMinimal onInput={city => { setCity(city) }} label="City" />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker autoFocus={true} format="dd-MM-yyyy" label="Start date"
                        defaultValue={startDate}
                        onChange={value => setStartDate(new Date(value as Date))} />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        format="dd-MM-yyyy"
                        defaultValue={endDate}
                        minDate={addDays(startDate, 1)}
                        maxDate={addDays(startDate, 30)}
                        label="End date"
                        onChange={value => setEndDate(new Date(value as Date))} />
                </LocalizationProvider>
                <Button variant='contained' sx={{ width: "10%", margin: 0 }} disabled={!city || !startDate || !endDate} onClick={e => {
                    e.preventDefault();
                    onSubmit(city!, startDate, endDate!);
                }}> Start</Button>
            </Stack>
        </Box>
    </>
}