import { User } from "../core/types";

export function saveUser(user: User | null): void {
    localStorage.setItem("user", JSON.stringify(user));
}

export function getUser(): User | null {
    const userJson = localStorage.getItem("user");
    if (userJson) {
        const user =  JSON.parse(userJson) as User;
        return user;
    } else {
        return null;
    }
}