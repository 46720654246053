import CloseIcon from '@mui/icons-material/Close';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, MenuItem, Select, Slider, Stack, Typography } from "@mui/material";
import { Mark } from '@mui/material/Slider/useSlider.types';
import React, { useState } from "react";
import axiosClient from '../core/axiosclient';
import { Place, categoryTree } from "../core/types";
import PlacesMap from "./PlacesMap";

export interface Props {
    selectedPlaces: Place[];
    onPlaceSelect: (selected: boolean, place: Place) => void;
    clusterPlaces: Place[];
}

export default function NearbySearch({ selectedPlaces, onPlaceSelect, clusterPlaces }: Props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [radius, setRadius] = useState<number>(3);
    const [places, setPlaces] = useState<Place[]>([]);
    const [category, setCategory] = useState<string>("Food & Drink");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
        setPlaces([]);
    };

    const nearbySearch = () => {
        setLoading(true);
        axiosClient.post("/places/search/nearby", {
            city: selectedPlaces[0].city,
            radius: radius / 2,
            anyOfTypes: categoryTree.get(category) as string[],
            locations: clusterPlaces
                .map(p => {
                    return {
                        latitude: p.latitude,
                        longitude: p.longitude
                    };
                })
        }).then((res) => {
            const result = res.data;
            setPlaces(result);
        }).finally(() => setLoading(false));
    }

    const buildDialogContent = (): React.ReactNode => {

        const menuItems: React.ReactNode[] = [];
        categoryTree.forEach((k, v) => {
            menuItems.push(<MenuItem value={v}>{v}
            </MenuItem>)
        })

        const minRadius = 1;
        const maxRadius = 10;
        const radiusMarks = [{ value: minRadius, label: minRadius + " km" },
        { value: maxRadius, label: maxRadius + " km" }];

        return <>
            <Box>
                <Box alignContent={"center"} width={"100%"}>
                    <Stack spacing={1} margin={1} direction={"column"} alignContent={"center"} alignItems={"center"}>
                        <Select
                            sx={{ width: "80%" }}
                            disabled={loading}
                            onChange={(e) => setCategory(e.target.value)}
                            value={category}>
                            {menuItems}
                        </Select>
                        <Box width={"80%"}>
                            <Typography>Radius</Typography>
                            <Slider disabled={loading} onChange={(_, value) => setRadius(value as number)}
                                min={minRadius}
                                step={1}
                                marks={radiusMarks as Mark[]}
                                defaultValue={radius} getAriaValueText={(v) => `${v} km`}
                                max={10} aria-label="Default" valueLabelDisplay="auto" />
                        </Box>
                        <Button disabled={loading} onClick={() => nearbySearch()}>Search</Button>
                    </Stack>
                </Box>
                {loading && <LinearProgress />}
                {places.length > 0 && !loading && <PlacesMap places={places} selectedPlaces={selectedPlaces} onPlaceSelect={onPlaceSelect} />}
            </Box>
        </>
    }

    return <>
        <Button size="medium" variant="outlined" sx={{ width: {
            xs: "80%",
            sm: "80%",
            md: "20%",
            lg: "20%",
            xl: "20%"
        } }} startIcon={<TravelExploreIcon />} onClick={handleClickOpen}>
            Nearby search
        </Button>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="full-screen-dialog-title"
        >
            <DialogTitle sx={{ textAlign: "center" }} id="full-screen-dialog-title">
                Search places nearby
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {buildDialogContent()}
            </DialogContent>
        </Dialog>
    </>
}