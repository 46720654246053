import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GoogleIcon from '@mui/icons-material/Google';
import PaidIcon from '@mui/icons-material/Paid';
import { Box, Card, CardActions, CardContent, CardMedia, IconButton, Rating, Stack, Tooltip, Typography, styled } from "@mui/material";
import React from 'react';
import { Place } from "../core/types";
import OpeningHours from './OpeningHours';

interface CardProps {
    place: Place;
    selected: boolean;
    mini: boolean;
    onSelect: (selected: boolean, place:Place) => void;
    overflow?: React.ReactNode;
    withdMultiplier?: number;
}

export default function PlaceCard({ place, selected = false, mini = false, onSelect, overflow, withdMultiplier = 1 }: CardProps) {

    const PriceRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: "black",
        },
    });

    const buildImageUri = (str: string): string => {
        const suffix = "=w800";
        const result = str.substring(0, str.lastIndexOf("="));
        console.log(result)
        return result + suffix;
    }

    const buildPriceLevelComponent = (priceLevel: string | null): React.ReactNode => {
        let value = 0;
        if (priceLevel === 'PRICE_LEVEL_VERY_EXPENSIVE') {
            value = 4;
        }
        if (priceLevel === 'PRICE_LEVEL_EXPENSIVE') {
            value = 3;
        }
        if (priceLevel === 'PRICE_LEVEL_MODERATE') {
            value = 2;
        }
        if (priceLevel === 'PRICE_LEVEL_INEXPENSIVE') {
            value = 1;
        }
        if (priceLevel === 'PRICE_LEVEL_FREE') {
            value = 0;
        }
        if (priceLevel) {
            return <PriceRating
                size='small'
                readOnly
                max={4}
                name="customized-color"
                defaultValue={value}
                getLabelText={(value: number) => `${value} Money${value !== 1 ? 's' : ''}`}
                precision={0.5}
                icon={<PaidIcon fontSize="inherit" />}
                emptyIcon={<AttachMoneyIcon fontSize="inherit" />}
            />
        }
        return <></>
    }

    const cardWitdh = {
        xs: 350 * withdMultiplier,
        sm: 350 * withdMultiplier,
        md: 300 * withdMultiplier,
    }

    return (
        <Box sx={{ position: "relative", display: 'inline-block' }}>
            <Card sx={{
                flexFlow: 1,
                height: "100%",
                maxWidth: cardWitdh,
                minWidth: cardWitdh, display: 'flex', flexDirection: 'column'
            }} key={place.id}>
                <CardMedia
                    component="img"
                    sx={{
                        objectFit: 'cover',
                        width: '100%',
                        height: {
                            xs: 180,
                            sm: 140,

                        }
                    }}
                    image={buildImageUri(place.photo_uri)}
                />
                <CardContent sx={{ flexGrow: 1, fontSize: 24 }}>
                    <Typography gutterBottom>
                        {place.name}
                    </Typography>
                    <Typography textTransform={'capitalize'} variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        {place.primary_type ? place.primary_type.replaceAll("_", " ") : 'Tourist attraction'}
                    </Typography>
                    {!mini && <Typography variant="body2" sx={{
                        color: 'text.secondary', display: {
                            //(xs: 'none',  // Hide on extra-small screens (mobile)
                            sm: 'block', // Show on small screens (600px and above)
                        }
                    }}>
                        {place.description}
                    </Typography>}
                    <Stack direction='row' alignItems="center">
                        <Rating size='small' name="half-rating-read" value={place.user_rating} precision={0.1} readOnly />
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                            {place.user_rating}({new Intl.NumberFormat("en-US").format(place.user_rating_count)})
                        </Typography>
                    </Stack>
                    {buildPriceLevelComponent(place.price_level)}
                </CardContent>
                <CardActions>
                    <Tooltip title={`${selected ? "Remove from": "Add to"} selections`}>
                        <IconButton onClick={(e) => {
                            e.preventDefault();
                            onSelect(!selected, place)
                        }}>
                            <FavoriteIcon color={selected ? 'error' : 'disabled'}></FavoriteIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Go to Google Maps"}>
                        <IconButton onClick={(e) => {
                            window.open(place.google_maps_uri, "_blank");
                        }}>
                            <GoogleIcon></GoogleIcon>
                        </IconButton>
                    </Tooltip>
                    <OpeningHours periods={place.regular_opening_periods}></OpeningHours>
                </CardActions>
            </Card>
            {overflow ? overflow : <></>}
        </Box>
    )
}