import { Login } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";


export default function UserMenuItem() {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const buildLoggedInComponent = () => {
        console.log(user?.pictureUrl)
        return <>
            <IconButton onClick={() => navigate("/login")}>
                <Avatar alt={user?.name} src={user?.pictureUrl}>

                </Avatar>
            </IconButton>
        </>
    }

    const buildLoggedOutComponent = () => {
        return <>
            <IconButton onClick={() => navigate("/login")}>
                <Login color={"secondary"}>
                </Login>
            </IconButton>
        </>
    }

    return <>
        {user ? buildLoggedInComponent() : buildLoggedOutComponent()}
    </>
}