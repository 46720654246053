import { da, th } from "date-fns/locale";

export interface City {
  id: string;
  name: string;
  code: string;
  country: {
    code: string;
  };
}

export interface User {
  id: string;
  name:  string;
  email:  string;
  pictureUrl?:  string;
}

export type PlacePeriodDetail = { day: number; hour: number; minute: number };

export type PlacePeriod = {
  open: PlacePeriodDetail;
  close: PlacePeriodDetail;
};
export interface Place {
  photo_uri: string;
  id: string;
  name: string;
  city: string;
  description: string;
  types: string[];
  website_uri: string;
  address: string;
  rating: number;
  primary_type: string;
  user_rating_count: number;
  user_rating: number;
  latitude: number; longitude: number;
  google_maps_uri: string;
  price_level: string | null; // not sure
  regular_opening_periods: PlacePeriod[] 
}

export class VisitDay {
  date!: Date;
  places!: Place[];

  constructor(date: Date, places: Place[]) {
    this.date = date;
    this.places = places;
  }
}

export const categoryTree = new Map<string, string[]>([
  ['Culture', ['art_gallery', 'museum', 'performing_arts_theater']],
  ['Entertainment & Recreation', ['amusement_center', 'amusement_park', 'aquarium', 'historical_landmark', 'tourist_attraction', 'park', 'night_club', 'casino']],
  ['Food & Drink', [
      'american_restaurant',
      'bakery',
      'bar',
      'barbecue_restaurant',
      'brazilian_restaurant',
      'breakfast_restaurant',
      'brunch_restaurant',
      'cafe',
      'chinese_restaurant',
      'coffee_shop',
      'fast_food_restaurant',
      'french_restaurant',
      'greek_restaurant',
      'hamburger_restaurant',
      'ice_cream_shop',
      'indian_restaurant',
      'indonesian_restaurant',
      'italian_restaurant',
      'japanese_restaurant',
      'korean_restaurant',
      'lebanese_restaurant',
      'mediterranean_restaurant',
      'mexican_restaurant',
      'middle_eastern_restaurant',
      'pizza_restaurant',
      'ramen_restaurant',
      'restaurant',
      'sandwich_shop',
      'seafood_restaurant',
      'spanish_restaurant',
      'steak_house',
      'sushi_restaurant',
      'thai_restaurant',
      'turkish_restaurant',
      'vegan_restaurant',
      'vegetarian_restaurant',
      'vietnamese_restaurant'
  ]],
  ['Places of Worship', ['church', 'hindu_temple', 'mosque', 'synagogue']],
]);
