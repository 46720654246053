import CloseIcon from '@mui/icons-material/Close';
import RoomIcon from '@mui/icons-material/Room';
import { Badge, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from 'react';
import { Place } from "../core/types";
import PlacesMap from './PlacesMap';

export interface SelectedPlacesProps {
    selectedPlaces: Place[];
    onPlaceSelect: (selected: boolean, place: Place) => void;
}

export default function SelectedPlaces({ selectedPlaces, onPlaceSelect }: SelectedPlacesProps) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <Tooltip title={"Selected places"}>
            <IconButton onClick={handleClickOpen}>
                <Badge badgeContent={selectedPlaces.length} color="primary">
                    <RoomIcon color="action" />
                </Badge>
            </IconButton>
        </Tooltip>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="full-screen-dialog-title"
        >
            <DialogTitle sx={{textAlign:"center"}} id="full-screen-dialog-title">
                Selections
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack direction='column'>
                    {selectedPlaces.length == 0 ? <Typography>No place is selected</Typography> :
                        <PlacesMap onPlaceSelect={onPlaceSelect} places={selectedPlaces} selectedPlaces={selectedPlaces}></PlacesMap>}
                </Stack>
            </DialogContent>
        </Dialog>
    </>
}