import FavoriteIcon from '@mui/icons-material/Favorite';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import { AppBar, Box, Button, IconButton, LinearProgress, Stack, Tooltip, Typography, keyframes } from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosClient from "../core/axiosclient";
import { Place, VisitDay } from "../core/types";
import Itinerary from './Itinerary';
import PlaceCard from "./PlaceCard";
import PlaceCategories from "./PlaceCategories";
import SearchPlace from './SearchPlace';
import SelectedPlaces from './SelectedPlaces';
import TripPlannerStarter from "./TripPlannerStarter";

enum Step {
    Destination = "DESTINATION",
    Selection = "SELECTION",
    Itinerary = "ITINERARY",
}

export default function PlacesOfInterest() {
    const [step, setStep] = useState<Step>(Step.Destination);
    const [city, setCity] = useState<string>();
    const [keyword, setKeyword] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [types, setTypes] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [places, setPlaces] = useState<Place[]>([]);
    const [selectedPlaces, setSelectedPlaces] = useState<Place[]>([]);
    const [visitDays, setVisitDays] = useState<VisitDay[]>([]);

    useEffect(() => {
        if (city) {
            applyFilter();
        }
    }, [city, types, keyword]);

    const createItinerary = () => {
        setLoading(true);
        axiosClient.post("/plan", {
            placeIds: selectedPlaces.map(p => p.id),
            startDate: startDate,
            endDate: endDate
        }).then((res) => {
            const result = res.data;
            const casted = result.days.map((day: { date: string | number | Date; places: Place[]; }) => {
                return new VisitDay(new Date(day.date), day.places);
            })
            setVisitDays(casted);
        }).finally(() => setLoading(false));
    }

    const applyFilter = () => {
        setLoading(true);
        axiosClient.post("/places/search", {
            city: city,
            keyword: keyword ? keyword : null,
            oneOfTypes: types.length === 0 ? null : types
        }).then((res) => {
            const result = res.data;
            setPlaces(result);
        }).finally(() => setLoading(false));
    };

    const buildHeader = (): React.ReactNode => {
        const pulse = keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
        `;
        return <>
            {loading && <LinearProgress color="success" />}
            {step === Step.Destination ? <></> :
                <AppBar sx={{ color: (theme) => theme.palette.primary.main }} position="sticky">
                    <Stack borderRadius={'1px'} bgcolor={(theme) => theme.palette.secondary.main}
                        spacing={4}
                        direction={'row'}
                        flexWrap={'wrap'}
                        alignItems={'center'}
                        justifyContent={'space-between'}>
                        <Stack alignItems={"center"} margin={1} spacing={2} direction={'row'}>
                            <IconButton sx={{ height: "10%" }} onClick={(e) => {
                                if (step === Step.Selection) {
                                    setPlaces([])
                                    setTypes([])
                                    setCity(undefined)
                                    setSelectedPlaces([])
                                    setLoading(false)
                                    setStep(Step.Destination);
                                }
                                if (step === Step.Itinerary) {
                                    setStep(Step.Selection);
                                }
                            }}>
                                <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
                            </IconButton>
                            <Stack margin={"20px"} spacing={1} height={"80px"} direction={'column'}>
                                <Typography variant='h6' textTransform={'capitalize'}>{city}</Typography>
                                <Typography >{startDate?.toDateString()} - {endDate?.toDateString()}</Typography>
                            </Stack>
                        </Stack>
                        {step == Step.Selection && (
                            <Stack direction={"column"}>
                                <Stack direction={"row"} spacing={1} margin={1}>
                                    <Typography fontWeight={"bold"}>Select places to create itinerary</Typography>
                                    <FavoriteIcon
                                        sx={{
                                            animation: `${pulse} 1.5s infinite`,
                                        }}
                                        color={"error"}></FavoriteIcon>

                                </Stack>
                                <Stack margin={1} spacing={1} alignItems={"center"} justifyContent={"left"} direction={"row"}>
                                    <PlaceCategories categorySelected={(selection) => {
                                        setTypes(selection);
                                    }}></PlaceCategories>
                                    <SearchPlace keywordChange={(keyword) => { setKeyword(keyword) }}></SearchPlace>
                                </Stack>
                            </Stack>
                        )}
                        <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            {step === Step.Selection && (
                                <>
                                    <SelectedPlaces selectedPlaces={selectedPlaces} onPlaceSelect={onPlaceSelect} />
                                    <Button disabled={selectedPlaces.length - 1 < (endDate!.getDay() - startDate!.getDay())} variant='contained' endIcon={<SendIcon />} onClick={(event) => {
                                        event.preventDefault();
                                        createItinerary();
                                        setStep(Step.Itinerary);
                                    }}>Create Itinerary</Button>
                                </>
                            )}
                            {step === Step.Itinerary && (
                                <Tooltip title={"Chage plan"}>
                                    <Button sx={{textTransform:"none"}} variant="outlined" onClick={() => { createItinerary() }} endIcon={<RefreshIcon />}>
                                        Readjust
                                    </Button>
                                </Tooltip>
                            )}
                        </Stack>
                    </Stack>
                </AppBar>
            }
        </>
    }

    const buildDestinationComponent = (): React.ReactNode => {
        return <>
            <TripPlannerStarter onSubmit={(city, startDate, endDate) => {
                setCity(city);
                setStartDate(startDate);
                setEndDate(endDate);
                setStep(Step.Selection);
                //applyFilter();
            }}></TripPlannerStarter>
        </>
    }

    const buildItineraryComponent = (): React.ReactNode => {
        return <>
            {visitDays.length > 0 && <Itinerary onPlaceSelect={onPlaceSelect}
                selectedPlaces={selectedPlaces}
                days={visitDays.sort((d1, d2) => d1.date.getTime() - d2.date.getTime())}></Itinerary>}
        </>
    }

    const buildPlacesContainer = (): React.ReactNode => {
        return <Stack
            spacing={1}
            margin={1}
            useFlexGap={true}
            justifyContent={"flex-start"}
            alignItems={'stretch'}
            alignContent={'flex-start'}
            direction={'row'}
            flexWrap={'wrap'}>
            {places.map(p => {
                return <PlaceCard mini={false} place={p} selected={selectedPlaces.some(item => item.id === p.id)} onSelect={onPlaceSelect}></PlaceCard>
            })}
        </Stack>
    }

    const buildSelectionComponent = (): React.ReactNode => {
        return <>
            <Box>
                {buildPlacesContainer()}
            </Box>
        </>
    }

    return (
        <>
            <Stack>
                {buildHeader()}
                {step === Step.Destination && (
                    buildDestinationComponent()
                )}
                {step === Step.Selection && (
                    buildSelectionComponent()
                )}
                {step === Step.Itinerary && (
                    buildItineraryComponent()
                )}
            </Stack>
        </>
    )

    function onPlaceSelect(selected: boolean, place: Place) {
        if (selected) {
            if (!selectedPlaces.some(p => p.id === place.id)) {
                setSelectedPlaces((prev) => {
                    const updated = [...prev];
                    updated.push(place);
                    return updated;
                });
            }
        } else {
            removeSelection(place.id);
        }
    }

    function removeSelection(placeId: string) {
        setSelectedPlaces((prev) => {
            const updated = [...prev];
            return updated.filter(selectedPlace => selectedPlace.id !== placeId);
        });
    }
}