
import FilterListIcon from '@mui/icons-material/FilterList';
import { Badge, Button, Drawer, IconButton, capitalize } from '@mui/material';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useState } from 'react';
import { categoryTree } from '../core/types';

interface CategoryProps {
    categorySelected: (selection: string[]) => void;
}

export default function PlaceCategories({ categorySelected }: CategoryProps) {

    const [open, setOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string | null>(null);
    const [invisible, setInvisible] = useState(false);

    const handleBadgeVisibility = () => {
        setInvisible(!invisible);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const buildTreeItem = (label: string) => {
        <TreeItem itemId={label} label={label} />
    };


    const getItemLabel = (string: string): string => {
        if (string.includes("_")) {
            const result = string.replaceAll("_", " ").replaceAll("restaurant", "");
            return capitalize(result);
        }
        return capitalize(string);
    }
    return (

        <>
            <Button sx={{height:"40px"}} variant="outlined" onClick={handleClickOpen}>
                Filters
                <Badge color="primary" variant='dot' invisible={!selectedItems}>
                    <FilterListIcon />
                </Badge>
            </Button>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <Box>
                    <SimpleTreeView selectedItems={selectedItems}
                        checkboxSelection onSelectedItemsChange={(e, itemIds) => {
                            setSelectedItems(itemIds)
                            handleClose();
                            if (itemIds) {
                                const selection = categoryTree.get(itemIds);
                                if (selection) {
                                    categorySelected(selection as string[])
                                } else {
                                    categorySelected([itemIds])
                                }
                            } else {
                                categorySelected([])
                            }
                        }}>
                        {Array.from(categoryTree.keys()).map((rootCategory) => (
                            <TreeItem itemId={rootCategory} label={rootCategory} key={rootCategory}>
                                {categoryTree.get(rootCategory)?.map((item) => (
                                    <TreeItem itemId={item} label={getItemLabel(item)} key={item} />
                                ))}
                            </TreeItem>
                        ))}
                    </SimpleTreeView>
                </Box>
            </Drawer>
        </>
    );
}