import { Box, Button, ButtonGroup, Stack } from "@mui/material";
import React, { useState } from "react";
import { Place, VisitDay } from "../core/types";
import NearbySearch from "./NearbySearch";
import PlacesMap from "./PlacesMap";

export interface ItineraryProps {
    days: VisitDay[];
    selectedPlaces: Place[];
    onPlaceSelect: (selected: boolean, place: Place) => void;
}

export default function Itinerary({ days, selectedPlaces, onPlaceSelect }: ItineraryProps) {
    const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);

    const buildDateSelectComponent = (dates: Date[]): React.ReactNode => {
        return <>
            <ButtonGroup sx={{ display: "flex", flexWrap: "wrap" }}>
                {dates.map((date, i) => {
                    return <Button variant={i === selectedDateIndex ? "contained" : "text"}
                        onClick={() => setSelectedDateIndex(i)}>{date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric' })}</Button>
                })}
            </ButtonGroup>
        </>
    }

    const buildMapComponent = (visitDay: VisitDay | undefined): React.ReactNode => {
        if (!visitDay) {
            return <></>
        }
        return <PlacesMap places={visitDay.places} selectedPlaces={selectedPlaces} onPlaceSelect={onPlaceSelect}></PlacesMap>
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={1} margin={1}  alignItems={"center"} alignContent={"center"}>
                {buildDateSelectComponent(days.map((d) => d.date))}
                <NearbySearch selectedPlaces={selectedPlaces}
                    onPlaceSelect={(selected, place) => {
                        onPlaceSelect(selected, place);
                        if(selected) {
                            days[selectedDateIndex].places.push(place)
                        }
                    }}
                    clusterPlaces={days[selectedDateIndex].places} />
                {buildMapComponent(days[selectedDateIndex])}
            </Stack>
        </Box>
    );
}