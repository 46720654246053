import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link } from "react-router-dom";
import UserMenuItem from "../UserMenuItem";

const pages_ = [
  {
    menuTitle: "Home",
    pageURL: "/",
  },
  {
    menuTitle: "Trips",
    pageURL: "/trips",
  },
  {
    menuTitle: "About",
    pageURL: "/about",
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar sx={{width:"100%"}} position="relative">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: 60,
              width: 60,
            }}
            alt="Octoboo"
            src="logo.png"
          />
          <Box margin={2}></Box>
          <Typography fontWeight={"bold"}>
            Planing trips simplified
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages_.map((page, index) => {
                const { menuTitle, pageURL } = page;
                return (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Typography
                      key={index}
                      component={Link}
                      to={pageURL}
                      color="primary"
                    >
                      {menuTitle}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>

          <Box
            m={1}
            display="flex"
            justifyContent="flex-end" // flex-start -> for left , center-> center, flex-end-> for left
            alignItems="flex-end"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages_.map((page, index) => {
              const { menuTitle, pageURL } = page;
              return (
                <Button
                  key={index}
                  component={Link}
                  to={pageURL}
                  //variant="filled"
                  color="inherit"
                >
                  {menuTitle}
                </Button>
              );
            })}
          </Box>
          <UserMenuItem/>
          <IconButton />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
