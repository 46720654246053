import { ThemeProvider } from "@emotion/react";
import { CssBaseline, Typography, createTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import PlacesOfInterest from "./components/PlacesOfInterest";
import OAuthSignInPage from "./components/SignIn";
import UserProvider from "./components/UserContext";
import ResponsiveAppBar from "./components/main/ResponsesiveAppBar";

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: "#04233e",
    },
    secondary: {
      main: "#dae3e3",
    },
    background: {
      default: '#e8eded',
      paper: '#e6edec',
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: "system-ui",
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTypography: {
      defaultProps: {},
    },
  },
});

function App() {
  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId="288768177053-5dv7911mo4of4ihj8bfn14f7a56gmqtd.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UserProvider>
            <ResponsiveAppBar />
            <Routes>
              <Route path="/" element={<PlacesOfInterest />} />
              <Route path="/trips" element={<PlacesOfInterest />} />
              <Route path="/login" element={<OAuthSignInPage />} />
              <Route path="/about" element={<AboutUs />} />
              <Route
                path="/contact"
                element={<Typography>Contact us</Typography>}
              />
            </Routes>
          </UserProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </React.Fragment>
  );
}

export default App;
